/* eslint-disable */
import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  CssBaseline,
  Collapse,
  Menu,
  MenuItem
} from "@material-ui/core";
import { 
  ChevronLeft,
  ChevronRight,
  Assessment,
  ExpandMore,
  ExpandLess,
  Person,
  Add,
  PieChart,
  LocationCity,
  Category,
  Mail,
  Storefront,
  CheckBoxOutlineBlank,
  AttachMoney,
  Business,
  LocalOffer,
  PhotoLibrary
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu'
import ActiveComponent from './activeComponent/ActiveComponent'
import { setActiveComponent } from "../config/reduxstore/actions/ui/setActiveComponent"
import { tokenExpired } from '../config/reduxstore/actions/user/tokenExpired'
import { clearLocalStorage } from "../config/utilities/localStorage"
import { openCustomDialog } from "../config/reduxstore/actions/ui/openCustomDialog"
import SelectView from "./SelectView";
// import getAllProducts from "../config/requests/product/getAllProducts"
// import getAllSales from "../config/requests/sale/getAllSales"
// import getAllUsers from "../config/requests/user/getAllUsers"
// import getLocation from "../config/requests/location/getLocation";
// import getLocations from "../config/requests/location/getLocations"
// import getSubLocation from "../config/requests/sublocation/getSubLocation";
// import getSubLocations from "../config/requests/sublocation/getSubLocations";
// import getCategories from "../config/requests/category/getCategories";
// import getVendors from "../config/requests/vendor/getVendors";
// import getBays from "../config/requests/bay/getBays";
// import getAllMedia from "../config/requests/media/getAllMedia";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    color: theme.typography.color,
    flexGrow: 1,
    fontSize: 22
  },
  title2: {
    color: theme.typography.color,
    fontSize: 22,
    alignItems: 'right'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color: theme.typography.color,
    marginRight: theme.spacing(2),
  },
  person: {
    color: theme.typography.color,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: theme.palette.background.default,
    display: "inline-block",
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logo: {
    position: "relative",
    zIndex: "1",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      // backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)"
    }
  },
  logoImage: {
    width: "30px",
    display: "inline-block",
    maxHeight: "30px",
  },
  img: {
    width: drawerWidth,
    top: "0px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0"
  },
}));

export default function Main() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const activeComponent = useSelector((state) => state.ui.activeComponent.component);
  const locationId = useSelector((state) => state.user.currentUser.location_id);
  const subLocationId = useSelector((state) => state.user.currentUser.sub_location_id);
  const userLevel = useSelector((state) => state.user.currentUser.user_level);
  const locationName = useSelector(state => state.location.locationName.name)
  const subLocationName = useSelector(state => state.sublocation.subLocationName.name)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState("")
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (value) => {
    if (selectedIndex === value) {
      setSelectedIndex("")
    } else {
      setSelectedIndex(value)
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (userLevel && locationId) {
  //     getAllProducts(locationId, dispatch, setIsLoading)
  //     getAllSales(locationId, dispatch, setIsLoading)
  //     getAllUsers(locationId, dispatch, setIsLoading)
  //     getLocation(locationId, dispatch, setIsLoading)
  //     getCategories(locationId, subLocationId, dispatch, setIsLoading)
  //     getVendors(locationId, subLocationId, dispatch, setIsLoading)
  //     getBays(locationId, subLocationId, dispatch, setIsLoading)
  //     getAllMedia(locationId, subLocationId, dispatch, setIsLoading)
  //     if (subLocationId !== 0 && subLocationId !== '0' && subLocationId !== undefined) {
  //       getSubLocation(subLocationId, dispatch, setIsLoading)
  //     }
  //     getLocations(dispatch, setIsLoading)
  //     getSubLocations(userLevel, locationId, dispatch, setIsLoading)
  //   }
  // }, [dispatch, locationId])

  const handleSetActiveComponent = (componentName) => {
    dispatch(
      setActiveComponent(`${componentName}`)
    )
  };

  const handleLogout = () => {
    clearLocalStorage()
    dispatch(tokenExpired())
    dispatch({ type: "DESTROY_SESSION" });
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyProfile = () => {
    dispatch(
      openCustomDialog("dialogs/UserProfile")
    )
  };

  const handleContact = () => {
    dispatch(
        openCustomDialog("dialogs/Contact")
    )
  };

  // const brand = (
  //   <div className={classes.logo}>
  //       <div className={classes.logoImage}>
  //         <img src={logo} alt="logo" className={classes.img} />
  //       </div>
  //   </div>
  // );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} noWrap>
            IMS - Location: {locationName}
          </Typography>
          {subLocationName ?
          <Typography className={classes.title2}>
            Sublocation: {subLocationName}
          </Typography> : null}
          <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                className={classes.person}
                color="inherit"
              >
                <Person />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={handleMyProfile}>My Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* {brand} */}
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        {userLevel === 1 &&
        <List>
          <ListItem button onClick={() => handleClick(0)}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Users" />
            {selectedIndex === 0 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 0 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Users`)}>
                <ListItemIcon>{<Person />}</ListItemIcon>
                <ListItemText primary={"Manage Users"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddUserView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add User"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(1)}>
            <ListItemIcon>
              <LocationCity />
            </ListItemIcon>
            <ListItemText primary="Locations" />
            {selectedIndex === 1 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 1 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Locations`)}>
                <ListItemIcon>{<LocationCity />}</ListItemIcon>
                <ListItemText primary={"Manage Locations"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddLocationView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Location"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(2)}>
            <ListItemIcon>
              <Business />
            </ListItemIcon>
            <ListItemText primary="Sublocations" />
            {selectedIndex === 2 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 2 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`SubLocations`)}>
                <ListItemIcon>{<Business />}</ListItemIcon>
                <ListItemText primary={"Manage Sublocations"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddSubLocationView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Sublocation"} />
              </ListItem>
            </List>
          </Collapse>
        </List>
        }
        {userLevel === 2 &&
        <List>
          <ListItem button onClick={() => handleClick(0)}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Users" />
            {selectedIndex === 0 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 0 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Users`)}>
                <ListItemIcon>{<Person />}</ListItemIcon>
                <ListItemText primary={"Manage Users"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddUserView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add User"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(2)}>
            <ListItemIcon>
              <Business />
            </ListItemIcon>
            <ListItemText primary="Sublocations" />
            {selectedIndex === 2 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 2 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`SubLocations`)}>
                <ListItemIcon>{<Business />}</ListItemIcon>
                <ListItemText primary={"Manage Sublocations"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddSubLocationView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Sublocation"} />
              </ListItem>
            </List>
          </Collapse>
        </List>
        }
        {userLevel === 3 &&
          <List>
            <ListItem button onClick={() => handleClick(3)}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Users" />
              {selectedIndex === 3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={selectedIndex === 3 ? true : false} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Users`)}>
                  <ListItemIcon>{<Person />}</ListItemIcon>
                  <ListItemText primary={"Manage Users"} />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddUserView`)}>
                  <ListItemIcon>{<Add />}</ListItemIcon>
                  <ListItemText primary={"Add User"} />
                </ListItem>
              </List>
            </Collapse>
          </List>
        }
        <Divider />
        <List>
          <ListItem button onClick={() => handleClick(4)}>
            <ListItemIcon>
              <Category />
            </ListItemIcon>
            <ListItemText primary="Categories" />
            {selectedIndex === 4 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 4 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Categories`)}>
                <ListItemIcon>{<Category />}</ListItemIcon>
                <ListItemText primary={"Manage Categories"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddCategoryView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Category"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(5)}>
            <ListItemIcon>
              <Storefront />
            </ListItemIcon>
            <ListItemText primary="Vendors" />
            {selectedIndex === 5 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 5 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Vendors`)}>
                <ListItemIcon>{<Storefront />}</ListItemIcon>
                <ListItemText primary={"Manage Vendors"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddVendorView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Vendor"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(6)}>
            <ListItemIcon>
              <CheckBoxOutlineBlank />
            </ListItemIcon>
            <ListItemText primary="Areas/Bays" />
            {selectedIndex === 6 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 6 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Bays`)}>
                <ListItemIcon>{<CheckBoxOutlineBlank />}</ListItemIcon>
                <ListItemText primary={"Manage Areas/Bays"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddBayView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Areas/Bays"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(7)}>
            <ListItemIcon>
              <PhotoLibrary />
            </ListItemIcon>
            <ListItemText primary="Media/Images" />
            {selectedIndex === 7 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 7 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Media`)}>
                <ListItemIcon>{<PhotoLibrary />}</ListItemIcon>
                <ListItemText primary={"Manage Media/Images"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddMediaView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Media/Images"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(8)}>
            <ListItemIcon>
              <LocalOffer />
            </ListItemIcon>
            <ListItemText primary="Products" />
            {selectedIndex === 8 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 8 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Products`)}>
                <ListItemIcon>{<LocalOffer />}</ListItemIcon>
                <ListItemText primary={"Manage Products"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddProductView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Products"} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => handleClick(9)}>
            <ListItemIcon>
              <AttachMoney />
            </ListItemIcon>
            <ListItemText primary="Sales" />
            {selectedIndex === 9 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 9 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Sales`)}>
                <ListItemIcon>{<AttachMoney />}</ListItemIcon>
                <ListItemText primary={"Manage Sales"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`AddSaleView`)}>
                <ListItemIcon>{<Add />}</ListItemIcon>
                <ListItemText primary={"Add Sale"} />
              </ListItem>
            </List>
          </Collapse>
          {userLevel === 1 &&
          <div>
          <ListItem button onClick={() => handleClick(10)}>
            <ListItemIcon>
              <Assessment />
            </ListItemIcon>
            <ListItemText primary="Reports/Status" />
            {selectedIndex === 10 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 10 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Stats`)}>
                <ListItemIcon>{<PieChart />}</ListItemIcon>
                <ListItemText primary={"Status"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`ProductReports`)}>
                <ListItemIcon>{<LocalOffer />}</ListItemIcon>
                <ListItemText primary={"Product Reports"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`SaleReports`)}>
                <ListItemIcon>{<AttachMoney />}</ListItemIcon>
                <ListItemText primary={"Sale Reports"} />
              </ListItem>
            </List>
          </Collapse>
          </div>
          }
          {userLevel === 2 &&
          <div>
          <ListItem button onClick={() => handleClick(10)}>
            <ListItemIcon>
              <Assessment />
            </ListItemIcon>
            <ListItemText primary="Reports/Status" />
            {selectedIndex === 10 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedIndex === 10 ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`Stats`)}>
                <ListItemIcon>{<PieChart />}</ListItemIcon>
                <ListItemText primary={"Status"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`ProductReports`)}>
                <ListItemIcon>{<LocalOffer />}</ListItemIcon>
                <ListItemText primary={"Product Reports"} />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => handleSetActiveComponent(`SaleReports`)}>
                <ListItemIcon>{<AttachMoney />}</ListItemIcon>
                <ListItemText primary={"Sale Reports"} />
              </ListItem>
            </List>
          </Collapse>
          </div>
          }
          <ListItem button onClick={() => handleContact()}>
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText primary="Contact Support" />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {isLoading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <div>
            {activeComponent ? <ActiveComponent /> : <SelectView />}
          </div>
        )}
      </main>
    </div>
  );
}
