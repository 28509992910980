/* eslint-disable */
// libraries
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import requestUserAuth from "../config/requests/user/auth";
import {
  FormControl,
  TextField,
  FilledInput,
  InputLabel,
  InputAdornment,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff, LockOpen } from "@material-ui/icons";
import logo from "../assets/VixalopexInventory.png"

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    padding: 20,
    width: 190,
  },
  actions: {
    padding: 20,
  },
}));

export default () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = () => {
      requestUserAuth({ email, password }, dispatch, setLoading);
      setLoading(true);
  };

  const signInDisabled = () => {
    return email.length > 0 && password.length > 0 && !loading
      ? false
      : true;
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter" && signInDisabled() === false) {
      handleSignIn();
    }
  };

  const passwordInputType = () => {
    return showPassword ? "text" : "password";
  };

  return (
    <>
      <div className={classes.content}>
      <img style={{
          // flex: 1,
          mixBlendModde: "difference",
          color: "transparent",
          // filter: "drop-shadow(6px 2px 10px black)",
          width: "20%",
          height: "20%",
          justifyContent: "center"
        }} src={logo} alt="Logo" />
        <Typography variant="h4" noWrap>
          Vixalopex IMS
        </Typography>
        <div className={classes.text}>
          <TextField
            id="email"
            label="Email"
            variant="filled"
            onChange={handleEmailChange}
            onKeyUp={handleKeyUp}
            disabled={loading}
          />
        </div>
        <div className={classes.text}>
          <FormControl variant="filled">
            <InputLabel htmlFor="password">Password</InputLabel>
            <FilledInput
              id="password"
              type={passwordInputType()}
              value={password}
              onChange={handlePasswordChange}
              onKeyUp={handleKeyUp}
              disabled={loading}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={signInDisabled()}
            onClick={handleSignIn}
          >
            {loading ? <CircularProgress size={24} /> : <LockOpen />}
            <span>Sign In</span>
          </Button>
        </div>
      </div>
    </>
  );
};
