/* eslint-disable */
// request urls
import { apiGetUser } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'
import { setLocalStorage } from '../../utilities/localStorage'

// actions
import { currentUser } from '../../reduxstore/actions/user/currentUser'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (userId, dispatch, setLoading) => {
  const request = await get(apiGetUser(userId))

  const { data, error } = request
  // handle success
  data.data.length > 0 && setLocalStorage('userId', data.data[0].id)
  data.data.length > 0  && setLocalStorage('userLevel', data.data[0].user_level)
  data.data.length > 0  && setLocalStorage('locationId', data.data[0].location_id)
  data.data.length > 0  && setLocalStorage('subLocationId', data.data[0].sub_location_id)
  data.data.length > 0  && dispatch(currentUser(data.data[0]))

  error && dispatch(setAlert('error', error))
  error && setLoading(false)
}
