// ui action constants
import {
  RETRIEVE_ALL_PRODUCTS_SUCCESS,
  CREATE_PRODUCT_SUCCESS,
  // RETRIEVE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  ALL_PRODUCTS_COUNT,
  ACTIVE_PRODUCTS_COUNT,
  INACTIVE_PRODUCTS_COUNT,
  PRODUCT_REPORT,
} from "../actions/types";

// default ui reducer
const defaultState = {
  allProducts: [],
  newProduct: {},
  activeProduct: {},
  updateProduct: {},
  deleteProduct: {},
  activeProductsCount: 0,
  inactiveProductsCount: 0,
  allProductsCount: 0,
  productReport: [],
  deletedProducts: []
}

// ui reducer
export default function init(state = defaultState, action) {
  switch (action.type) {

    case RETRIEVE_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: action.payload
      }

    case PRODUCT_REPORT:
      return {
        ...state,
        productReport: action.payload
      }

    case ALL_PRODUCTS_COUNT:
      return {
        ...state,
        allProductsCount: action.payload
      }

    case ACTIVE_PRODUCTS_COUNT:
      return {
        ...state,
        activeProductsCount: action.payload
      }

    case INACTIVE_PRODUCTS_COUNT:
      return {
        ...state,
        inactiveProductsCount: action.payload
      }

    case CREATE_PRODUCT_SUCCESS:
      const existingArray = state.allProducts;
      existingArray.push(action.payload);
      return {
        ...state,
        allProducts: existingArray
      }

    case UPDATE_PRODUCT_SUCCESS:
      const filteredArray = state.allProducts.filter(product => product.id !== parseInt(action.payload.id))
      filteredArray.push(action.payload);
      return {
        ...state,
        allProducts: filteredArray,
      }


    case DELETE_PRODUCT_SUCCESS:
      const filteredData = state.allProducts.filter(product => product.id !== action.payload)
      return {
        ...state,
        allProducts: filteredData
      }

    default:
      return state
  }
}
