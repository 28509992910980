import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      // main: "#4d81b7"
      main: "#FC8D24"
    },
    secondary: {
      // main: "#4d81b7"
      main: "#000000"
      // main: "#FFFFFF"
    },
    error: {
      main: "#ff0008"
    },
    background: {
      main: "#fff"
    }
  },
  typography: {
    color: "#FFFFFF",
    typography: {
      textDecoration: "line-through",
    },
  }
});

export default theme;

// A custom theme for this app
// export const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#FC8D24"
//     },
//     white: {
//       main: "#fff"
//     },
//     danger: {
//       main: "#4287f5"
//     },
//     secondary: {
//       main: "#000000"
//     },
//     error: {
//       main: "#ff0008"
//     },
//     background: {
//       main: "#fff"
//     },
//     weather: {
//       todayFontColor: "#fff",
//       forcastFontColor: "#fff",
//       forcastIconColor: "#fff",
//     }
//   }
// });

// export default theme;
