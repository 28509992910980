//user
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_TOKEN_EXPIRED = "USER_TOKEN_EXPIRED";
export const DESTROY_SESSION = "DESTROY_SESSION";
export const CURRENT_USER = "CURRENT_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

//products
export const RETRIEVE_ALL_PRODUCTS_SUCCESS = "RETRIEVE_ALL_PRODUCTS_SUCCESS";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const RETRIEVE_PRODUCT_SUCCESS = "RETRIEVE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const ALL_PRODUCTS_COUNT = "ALL_PRODUCTS_COUNT";
export const ACTIVE_PRODUCTS_COUNT = "ACTIVE_PRODUCTS_COUNT";
export const INACTIVE_PRODUCTS_COUNT = "INACTIVE_PRODUCTS_COUNT";
export const PRODUCT_REPORT = "PRODUCT_REPORT";

//media
export const MEDIA = "MEDIA";
export const CREATE_MEDIA_SUCCESS = "CREATE_MEDIA_SUCCESS";
export const ALL_MEDIA_SUCCESS = "ALL_MEDIA_SUCCESS";
export const UPDATE_MEDIA_SUCCESS = "UPDATE_MEDIA_SUCCESS";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";

//sales
export const RETRIEVE_ALL_SALES_SUCCESS = "RETRIEVE_ALL_SALES_SUCCESS";
export const CREATE_SALE_SUCCESS = "CREATE_SALE_SUCCESS";
export const RETRIEVE_SALE_SUCCESS = "RETRIEVE_SALE_SUCCESS";
export const UPDATE_SALE_SUCCESS = "UPDATE_SALE_SUCCESS";
export const DELETE_SALE_SUCCESS = "DELETE_SALE_SUCCESS";
export const ALL_SALES_COUNT = "ALL_SALES_COUNT";
export const ACTIVE_SALES_COUNT = "ACTIVE_SALES_COUNT";
export const INACTIVE_SALES_COUNT = "INACTIVE_SALES_COUNT";
export const SALES_REPORT = "SALES_REPORT";

//location
export const LOCATION = "LOCATION";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const LOCATIONS_SUCCESS = "LOCATION_SUCCESS";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS"

//sublocation
export const SUBLOCATION = "SUBLOCATION";
export const CREATE_SUBLOCATION_SUCCESS = "CREATE_SUBLOCATION_SUCCESS";
export const SUBLOCATIONS_SUCCESS = "SUBLOCATIONS_SUCCESS";
export const UPDATE_SUBLOCATION_SUCCESS = "UPDATE_SUBLOCATION_SUCCESS";
export const DELETE_SUBLOCATION_SUCCESS = "DELETE_SUBLOCATION_SUCCESS";

//category
export const CATEGORY = "CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CATEGORIES_SUCCESS = "CATEGORIES_SUCCESS";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

//vendor
export const VENDOR = "VENDOR";
export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS";
export const VENDORS_SUCCESS = "VENDORS_SUCCESS";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";

//bay
export const BAY = "BAY";
export const CREATE_BAY_SUCCESS = "CREATE_BAY_SUCCESS";
export const BAYS_SUCCESS = "BAYS_SUCCESS";
export const UPDATE_BAY_SUCCESS = "UPDATE_BAY_SUCCESS";
export const DELETE_BAY_SUCCESS = "DELETE_BAY_SUCCESS";

//ui
export const UI_SET_ALERT = "UI_SET_ALERT";
export const UI_SET_CONTENT = "UI_SET_CONTENT";
export const UI_OPEN_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_CLOSE_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_SET_ACTIVE_COMPONENT = "UI_SET_ACTIVE_COMPONENT";

//contact
export const EMAIL_SUCCESS = "EMAIL_SUCCESS";