/* eslint-disable */
// request urls
import { apiUserAuth } from '../../api/urls'

// utilities
import post from '../../utilities/fetch/post'
import { setLocalStorage } from '../../utilities/localStorage'

// actions
import { authSuccess } from '../../reduxstore/actions/user/authSuccess'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'
import { currentUser } from '../../reduxstore/actions/user/currentUser'

export default async (requestData, dispatch, setLoading) => {
  const request = await post(apiUserAuth(), requestData)
  const { data, error } = request
  // handle success
  data.token && setLocalStorage('token', data.token)
  data.token && dispatch(authSuccess(data.token))
  data.token && setLocalStorage('userId', data.id)
  data.token && setLocalStorage('userLevel', data.user_level)
  data.token && setLocalStorage('locationId', data.location_id)
  data.token && setLocalStorage('subLocationId', data.sub_location_id)
  data.token && dispatch(currentUser(data))

  data && data.code !== 200 && dispatch(setAlert('error', data.success)) && setLoading(false)
  error && dispatch(setAlert('error', error))
  error && setLoading(false)
}
