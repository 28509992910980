/* eslint-disable */
// user action constants
import {
    VENDOR,
    CREATE_VENDOR_SUCCESS,
    VENDORS_SUCCESS,
    UPDATE_VENDOR_SUCCESS,
    DELETE_VENDOR_SUCCESS
  } from "../actions/types";
  
  // default user state
  const defaultState = {
    vendor: {
      id: null,
      name: null,
      location_id: null,
      sub_location_id: null
    },
    newVendor: {},
    vendors: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case VENDOR:
        return {
          ...state,
          vendor: {
            id: action.payload.id,
            name: action.payload.name,
            location_id: action.payload.location_id,
            sub_location_id: action.payload.sub_location_id
          }
        }

      case VENDORS_SUCCESS:
        return {
          ...state,
          vendors: action.payload
        }

      case CREATE_VENDOR_SUCCESS:
        const existingArray = state.vendors;
        existingArray.push(action.payload);
        return {
          ...state,
          newVendor: action.payload
        }
  
      case UPDATE_VENDOR_SUCCESS:
        const filteredArray = state.vendors.filter(vendor => vendor.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          vendors: filteredArray,
        }

      case DELETE_VENDOR_SUCCESS:
        const filteredData = state.vendors.filter(vendor => vendor.id !== action.payload)
        return {
          ...state,
          vendors: filteredData
        }
    

      default:
        return state;
    }
  };
  