// api config
import { API_URL } from './config'

// products
export const apiGetAllProductsByLocation= (locationId) => `${API_URL}/products?location_id=${locationId}`
export const apiAddProduct = () => `${API_URL}/products`
export const apiGetAllProducts = () => `${API_URL}/products`
export const apiGetOneProduct = (productId) => `${API_URL}/products/${productId}`
export const apiUpdateProduct = (productId) => `${API_URL}/products/${productId}`
export const apiDeleteProduct = (productId) => `${API_URL}/products/${productId}`
export const apiProductsReport = () => `${API_URL}/products/allstats/stats`
export const apiCountAllProducts = () => `${API_URL}/products/count`

// sales
export const apiGetAllSalesByLocation= (locationId) => `${API_URL}/sales?location_id=${locationId}`
export const apiAddSale = () => `${API_URL}/sales`
export const apiGetAllSales = () => `${API_URL}/sales`
export const apiGetOneSale = (saleId) => `${API_URL}/sales/${saleId}`
export const apiUpdateSale= (saleId) => `${API_URL}/sales/${saleId}`
export const apiDeleteSale = (saleId) => `${API_URL}/sales/${saleId}`
export const apiDeleteSaleWithProductData = (saleId) => `${API_URL}/salesproduct/${saleId}`
export const apiSalesReport = () => `${API_URL}/sales/allstats/stats`
export const apiCountAllSales = () => `${API_URL}/sales/count`

// register and login
export const apiRegister = () => `${API_URL}/register`
export const apiUserAuth = () => `${API_URL}/login`

// user
export const apiGetUser = (userId) => `${API_URL}/user/${userId}`
export const apiGetAllUsers = () => `${API_URL}/users`
export const apiGetAllUsersByLocation = (locationId) => `${API_URL}/users?location_id=${locationId}`
export const apiGetAllUsersByLocationAndStatus = (locationId, status) => `${API_URL}/users?location_id=${locationId}&status=0`
export const apiUpdateUser = (userId) => `${API_URL}/user/${userId}`
export const apiDeleteUser = (userId) => `${API_URL}/user/${userId}`
export const apiUpdateUserPassword = () => `${API_URL}/user/password`

// locations
export const apiGetLocation = (locationId) => `${API_URL}/locations/${locationId}`
export const apiUpdateLocation = (locationId) => `${API_URL}/locations/${locationId}`
export const apiDeleteLocation = (locationId) => `${API_URL}/locations/${locationId}`
export const apiGetLocations = () => `${API_URL}/locations`
export const apiAddLocation = () => `${API_URL}/locations`

// sub locations
export const apiGetSubLocation = (subLocationId) => `${API_URL}/sublocations/${subLocationId}`
export const apiUpdateSubLocation = (subLocationId) => `${API_URL}/sublocations/${subLocationId}`
export const apiDeleteSubLocation = (subLocationId) => `${API_URL}/sublocations/${subLocationId}`
export const apiGetSubLocations = () => `${API_URL}/sublocations`
export const apiAddSubLocation = () => `${API_URL}/sublocations`
export const apiGetSubLocationsByLocationId = () => `${API_URL}/location_sublocations`

// categories
export const apiGetCategory = (categoryId) => `${API_URL}/categories/${categoryId}`
export const apiUpdateCategory = (categoryId) => `${API_URL}/categories/${categoryId}`
export const apiDeleteCategory = (categoryId) => `${API_URL}/categories/${categoryId}`
export const apiGetCategories = () => `${API_URL}/categories`
export const apiAddCategory = () => `${API_URL}/categories`

// vendors
export const apiGetVendor = (vendorId) => `${API_URL}/vendors/${vendorId}`
export const apiDeleteVendor = (vendorId) => `${API_URL}/vendors/${vendorId}`
export const apiUpdateVendor = (vendorId) => `${API_URL}/vendors/${vendorId}`
export const apiGetVendors = () => `${API_URL}/vendors`
export const apiAddVendor = () => `${API_URL}/vendors`

//bays
export const apiGetBay = (bayId) => `${API_URL}/bays/${bayId}`
export const apiUpdateBay = (bayId) => `${API_URL}/bays/${bayId}`
export const apiDeleteBay = (bayId) => `${API_URL}/bays/${bayId}`
export const apiGetBays = () => `${API_URL}/bays`
export const apiAddBay = () => `${API_URL}/bays`

// media
export const apiGetMediaById = (mediaId) => `${API_URL}/media_by_id?id=${mediaId}`
export const apiDeleteMedia = (mediaId) => `${API_URL}/media_delete?id=${mediaId}`
export const apiUpdateMedia = (mediaId, locationId, subLocationId) => `${API_URL}/media_update?id=${mediaId}&location_id=${locationId}&sub_location_id=${subLocationId}`
export const apiAllMedia = () => `${API_URL}/media`
export const apiAddMedia = (locationId, subLocationId) => `${API_URL}/media?location_id=${locationId}&sub_location_id=${subLocationId}`

// contact
export const apiEmail = () => `https://ing0g4m48e.execute-api.us-east-1.amazonaws.com/vixalopexstageapi/vixalopexlambdaresource`