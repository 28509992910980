import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 22
    }
}));

export default function SelectView() {
    const classes = useStyles();

    return (
        <div display="flex">
            <Typography className={classes.title}>Select Your View On The Left!</Typography>
        </div>
    );
}
