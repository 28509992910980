/* eslint-disable */
// user action constants
import {
    BAY,
    CREATE_BAY_SUCCESS,
    BAYS_SUCCESS,
    UPDATE_BAY_SUCCESS,
    DELETE_BAY_SUCCESS
  } from "../actions/types";
  
  // default user state
  const defaultState = {
    bay: {
      id: null,
      name: null,
      location_id: null,
      sub_location_id: null
    },
    newBay: {},
    bays: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case BAY:
        return {
          ...state,
          bay: {
            id: action.payload.id,
            name: action.payload.name,
            location_id: action.payload.location_id,
            sub_location_id: action.payload.sub_location_id
          }
        }

      case BAYS_SUCCESS:
        return {
          ...state,
          bays: action.payload
        }

      case CREATE_BAY_SUCCESS:
        const existingArray = state.bays;
        existingArray.push(action.payload);
        return {
          ...state,
          newBay: action.payload
        }
  
      case UPDATE_BAY_SUCCESS:
        const filteredArray = state.bays.filter(bay => bay.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          bays: filteredArray,
        }

      case DELETE_BAY_SUCCESS:
        const filteredData = state.bays.filter(bay => bay.id !== action.payload)
        return {
          ...state,
          bays: filteredData
        }
    

      default:
        return state;
    }
  };
  