/* eslint-disable */
// user action constants
import {
    CATEGORY,
    CREATE_CATEGORY_SUCCESS,
    CATEGORIES_SUCCESS,
    UPDATE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_SUCCESS
  } from "../actions/types";
  
  // default user state
  const defaultState = {
    category: {
      id: null,
      name: null,
      location_id: null,
      sub_location_id: null
    },
    newCategory: {},
    categories: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case CATEGORY:
        return {
          ...state,
          category: {
            id: action.payload.id,
            name: action.payload.name,
            location_id: action.payload.location_id,
            sub_location_id: action.payload.sub_location_id
          }
        }

      case CATEGORIES_SUCCESS:
        return {
          ...state,
          categories: action.payload
        }

      case CREATE_CATEGORY_SUCCESS:
        const existingArray = state.categories;
        existingArray.push(action.payload);
        return {
          ...state,
          newCategory: action.payload
        }
  
      case UPDATE_CATEGORY_SUCCESS:
        const filteredArray = state.categories.filter(category => category.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          categories: filteredArray,
        }

      case DELETE_CATEGORY_SUCCESS:
        const filteredData = state.categories.filter(category => category.id !== action.payload)
        return {
          ...state,
          categories: filteredData
        }
    

      default:
        return state;
    }
  };
  