// ui action constants
import {
    RETRIEVE_ALL_SALES_SUCCESS,
    CREATE_SALE_SUCCESS,
    // RETRIEVE_SALE_SUCCESS,
    UPDATE_SALE_SUCCESS,
    DELETE_SALE_SUCCESS,
    ALL_SALES_COUNT,
    ACTIVE_SALES_COUNT,
    INACTIVE_SALES_COUNT,
    SALES_REPORT,
  } from "../actions/types";
  
  // default ui reducer
  const defaultState = {
    allSales: [],
    newSale: {},
    activeSale: {},
    updateSale: {},
    deleteSale: {},
    activeSalesCount: 0,
    inactiveSalesCount: 0,
    allSalesCount: 0,
    salesReport: [],
    deletedSales: []
  }
  
  // ui reducer
  export default function init(state = defaultState, action) {
    switch (action.type) {
  
      case RETRIEVE_ALL_SALES_SUCCESS:
        return {
          ...state,
          allSales: action.payload
        }
  
      case SALES_REPORT:
        return {
          ...state,
          salesReport: action.payload
        }
  
      case ALL_SALES_COUNT:
        return {
          ...state,
          allSalesCount: action.payload
        }
  
      case ACTIVE_SALES_COUNT:
        return {
          ...state,
          activeSalesCount: action.payload
        }
  
      case INACTIVE_SALES_COUNT:
        return {
          ...state,
          inactiveSalesCount: action.payload
        }
  
      case CREATE_SALE_SUCCESS:
        const existingArray = state.allSales;
        existingArray.push(action.payload);
        return {
          ...state,
          allSales: existingArray
        }
  
      case UPDATE_SALE_SUCCESS:
        const filteredArray = state.allSales.filter(sale => sale.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          allSales: filteredArray,
        }
  
  
      case DELETE_SALE_SUCCESS:
        const filteredData = state.allSales.filter(sale => sale.id !== action.payload)
        return {
          ...state,
          allSales: filteredData
        }
  
      default:
        return state
    }
  }
  