/* eslint-disable */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorage } from './config/utilities/localStorage'
import { authSuccess } from './config/reduxstore/actions/user/authSuccess'
import Main from "./components/Main";
import Login from "./components/Login";
import CustomDialog from "./components/customDialog/CustomDialog";
import Alert from "./components/alert/Alert"
import getUser from "./config/requests/user/getUser";
import getLocation from "./config/requests/location/getLocation"
import getSubLocation from "./config/requests/sublocation/getSubLocation"
import Footer from "./components/Footer";

function App() {
  const authenticated = useSelector((state) => state.user.authenticated);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // // auto relog when a user token exists
  const token = getLocalStorage("token")
  if (token) {
    dispatch(authSuccess(token));
  }
  const userId = getLocalStorage("userId")
  const locationId = getLocalStorage("locationId")
  const subLocationId = getLocalStorage("subLocationId")
  if (!authenticated && token && userId) {
    getUser(userId, dispatch, setLoading)
    getLocation(locationId, dispatch, setLoading)
    if (subLocationId !== 0 && subLocationId !== '0' && subLocationId !== undefined) {
      getSubLocation(subLocationId, dispatch, setLoading)
    }
  }
  return <div className="App">{authenticated && token ? <Main /> : <Login />}<CustomDialog /><Alert />{authenticated && token ? <Footer /> : null}</div>;
}

export default App;