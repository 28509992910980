/* eslint-disable */
// request urls
import { apiGetSubLocation } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'
import { setLocalStorage } from '../../utilities/localStorage'

// actions
import { setUserSubLocation } from '../../reduxstore/actions/sublocation/userSubLocation'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (subLocationId, dispatch, setLoading) => {
  const request = await get(apiGetSubLocation(subLocationId))

  const { data, error } = request
  // handle success
  data.data.length > 0  && setLocalStorage('subLocationName', data.data[0].name)
  data.data.length > 0  && dispatch(setUserSubLocation(data.data[0]))

  error && dispatch(setAlert('error', error))
  error && setLoading(false)
}
