/* eslint-disable */
// request urls
import { apiGetLocation } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'
import { setLocalStorage } from '../../utilities/localStorage'

// actions
import { setUserLocation } from '../../reduxstore/actions/location/userLocation'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (locationId, dispatch, setLoading) => {
  const request = await get(apiGetLocation(locationId))

  const { data, error } = request
  // handle success
  data.data.length > 0  && setLocalStorage('locationName', data.data[0].name)
  data.data.length > 0  && dispatch(setUserLocation(data.data[0]))

  error && dispatch(setAlert('error', error))
  error && setLoading(false)
}
