import { createStore, combineReducers } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import ui from "../reducers/ui";
import user from "../reducers/user";
import product from "../reducers/product";
import media from "../reducers/media";
import sale from "../reducers/sale";
import category from "../reducers/category";
import vendor from "../reducers/vendor";
import bay from "../reducers/bay";
import location from "../reducers/location";
import sublocation from "../reducers/sublocation";
import email from "../reducers/email";

import { DESTROY_SESSION } from "../actions/types";
// Combine all reducers.
const appReducer = combineReducers({
  ui,
  user,
  product,
  media,
  sale,
  category,
  vendor,
  bay,
  location,
  sublocation,
  email
});

const rootReducer = (state, action) => {   
   // Clear all data in redux store to initial.
   if(action.type === DESTROY_SESSION)
      state = undefined;
   return appReducer(state, action);
};

export default createStore(rootReducer, devToolsEnhancer());
